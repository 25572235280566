<template>
    <li class="nav-tabs__item">
        <router-link :to="{ name: 'interventie-thema-detail', params: { thema: theme.slug, aspect: aspect.slug, interventie: aspect.primaryIntervention.slug }}" class="block-link">
            {{ aspect.title }}

            <span><icon icon="chevron-right"/></span>
        </router-link>
    </li>
</template>

<script>
    // @ is an alias to /src
    import Icon from "@/components/Icon";

    export default {
        name: 'Interventie',
        components: {
            Icon
        },
        props: {
            aspect: Object,
            theme: Object,
        }
    }
</script>