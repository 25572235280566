<template>
    <div class="tabs-wrapper">
        <ul class="nav-tabs">
            <TabnavigationTab v-show="hasSelected()" label="Gekozen aspecten" :is-active="isTabActive('selected')" @click="setView('selected')" />
            <TabnavigationTab label="Alle aspecten" :is-active="isTabActive('all')" @click="setView('all')" />
        </ul>

        <div class="nav-tabs__pane fade show active">
            <div class="nav-tabs__content">
                <ul class="nav-tabs">
                    <TabnavigationItem v-for="aspect in aspectsByView" :key="aspect" :aspect="aspect" :theme="theme" />
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../data/api'
    import TabnavigationTab from "./TabnavigationTab";
    import TabnavigationItem from "./TabnavigationItem";
    import AspectsCollection from '../collections/AspectsCollection';

    export default {
        name: 'Interventie',
        components: {
            TabnavigationTab,
            TabnavigationItem
        },
        props: {
            theme: Object
        },
        data() {
            return {
                pageTitle: 'Interventies',
                aspects: new AspectsCollection(),
                view: 'selected'
            }
        },
        computed: {
            aspectsByView() {
                let view = this.view;

                if (!this.hasSelected()) {
                    view = 'all';
                }

                switch (view) {
                    case 'selected':
                        return this.aspects.filterByView(view).all();
                }
                
                return this.aspects.all();
            }
        },
        mounted() {
            api.fetchAspects(this.theme.id).then(response => {
                this.aspects = response;
            });
            
            this.$store.dispatch('fetchThemes');
        },
        methods: {
            setView(view) {
                this.view = view;
            },
            isTabActive(view) {

                return this.view == view || (view === 'all' && !this.hasSelected());
            },
            hasSelected() {
                return this.aspects.filterByView(this.view).all().length > 0;
            }
        },
    }
</script>